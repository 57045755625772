import React from "react";
import Layout from "../components/layout";
import ContentBlock from "../components/content-block";

const NotFoundPage = () => (
  <Layout>
    <ContentBlock
      heading="PAGE NOT FOUND."
      text="Looks like you went to a page that does not exist."
    />
  </Layout>
);

export default NotFoundPage;
